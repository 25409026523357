import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import TagsCard from 'components/Cards/Widgets/Tags';
import RecentPostsCard from 'components/Cards/Widgets/RecentPosts';
import { generateLogger } from 'utils/loggers';

const logger = generateLogger('tags_page');
const handleRecentPostClick = (path: string) => {
  logger.click('click_recent_posting', { path });
};
const handleTagClick = (tagName: string) => {
  logger.click('click_tag', { tagName });
};

const TagsPage = () => {
  useEffect(() => {
    logger.view();
  }, []);

  return (
    <Layout right={<RecentPostsCard onClick={handleRecentPostClick} />}>
      <SEO title="태그" />
      <TagsCard onClick={handleTagClick} />
    </Layout>
  );
};

export default TagsPage;
