import React from 'react';
import WidgetCard from 'components/Cards/Widgets';
import { Link } from 'gatsby';
import { Chip, Avatar, makeStyles, Theme } from '@material-ui/core';
import { useAllTags } from 'hooks/useAllTags';
import { usePageRouteUtils } from 'hooks/usePageRouteUtils';

const useStyles = makeStyles<Theme>(({ spacing, typography }) => ({
  nonDecorationText: {
    textDecoration: 'none',
  },
  chip: {
    fontSize: typography.caption.fontSize,
    marginRight: spacing(1),
    marginBottom: spacing(1),
  },
}));

interface Props {
  onClick?: (tag: string) => void;
}
const TagsCard = ({ onClick }: Props) => {
  const styles = useStyles();
  const tags = useAllTags();
  const { getTagPath } = usePageRouteUtils();

  return (
    <WidgetCard title="태그">
      {tags.map(({ fieldValue, totalCount }) => (
        <Link key={fieldValue} to={getTagPath(fieldValue)} className={styles.nonDecorationText}>
          <Chip
            className={styles.chip}
            size="small"
            label={fieldValue}
            avatar={<Avatar>{totalCount}</Avatar>}
            onClick={() => onClick?.(fieldValue)}
          />
        </Link>
      ))}
    </WidgetCard>
  );
};

export default TagsCard;
