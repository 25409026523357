import { useStaticQuery, graphql } from 'gatsby';
import { GroupItem } from 'models/query';
import { SupportedLanguages } from 'models/site';

interface TagsQueryResult {
  allMarkdownRemark: {
    group: Array<GroupItem<{ fields: { lang: SupportedLanguages } }>>;
  };
}
export const useAllTags = (lang: SupportedLanguages = 'ko') => {
  const {
    allMarkdownRemark: { group },
  } = useStaticQuery<TagsQueryResult>(graphql`
    query {
      allMarkdownRemark {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
          nodes {
            fields {
              lang
            }
          }
        }
      }
    }
  `);

  const currentLanguageTags = group.filter(
    ({ nodes }) => nodes.filter(({ fields }) => fields.lang === lang).length > 0
  );

  return currentLanguageTags
    .map(({ fieldValue, totalCount }) => ({ fieldValue, totalCount }))
    .sort((a, b) => b.totalCount - a.totalCount);
};
